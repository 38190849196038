<template>
  <v-container class="pa-8">

      <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
        <v-icon>mdi-web</v-icon> Risk Universe
        <div class="float-right row pr-4">
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="mr-4"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
          <router-link :to="{name: 'AddNewRisk'}" tag="button" >
          <v-btn
            color="primary"
            dark
            class="mb-2 col"   
          >
          <v-icon>mdi-plus</v-icon>
            New Risk
          </v-btn>
          </router-link>
        </div>
      </div>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
    
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div>
                <v-text-field
                  v-model="editedItem.RiskName"
                  label="Input Risk Name"
                  outlined
                >
                </v-text-field>
                <v-select
                :items="RiskClassification"
                item-text="RiskClassification"
                label="Select Classification"
                v-model="editedItem.RiskClassification"
                outlined
                return-object
                ></v-select>

                <v-text-field
                item-text="RiskUniverse"
                label="Input Risk Universe"
                v-model="editedItem.RiskUniverse"
                outlined
                ></v-text-field>
              
              </div>

              <div>
                <h3>Universal Causes</h3>
                <v-btn
                @click="addInput('UniversalCauses')"
                class="float-right mr-1 add-button-position"
                fab
                small
                color="primary"
                >
                  <v-icon dark>
                  mdi-plus
                  </v-icon>
                </v-btn>
                <v-text-field 
                v-for="(field, index) in editedItem.UniversalCauses" 
                :key="index" 
                :placeholder="index !== 0 ? 'Input another Universal Cause' : 'Input a Universal Cause'"
                v-model="field.text"
                :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                @click:append="deleteInput(index, 'UniversalCauses')"
                >
                </v-text-field>
              </div>

              <div class="mt-6">
                <h3>Universal Impacts</h3>
                <v-btn
                @click="addInput('UniversalImpacts')"
                class="float-right mr-1 add-button-position"
                fab
                small
                color="primary"
                >
                  <v-icon dark>
                  mdi-plus
                  </v-icon>
                  </v-btn>
                <v-text-field 
                v-for="(field, index) in editedItem.UniversalImpacts" 
                :key="index" 
                :placeholder="index !== 0 ? 'Input another Universal Impact' : 'Input a Universal Impact'"
                v-model="field.text"
                :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                @click:append="deleteInput(index, 'UniversalImpacts')"
                >
                </v-text-field>
              </div>

              <div class="mt-6">
                <h3>Universal Treatments</h3>
                <v-btn
                @click="addInput('UniversalTreatments')"
                class="float-right mr-1 add-button-position"
                fab
                small
                color="primary"
                >
                  <v-icon dark>
                  mdi-plus
                  </v-icon>
                </v-btn>
                <v-text-field 
                v-for="(field, index) in editedItem.UniversalTreatments" 
                :key="index" 
                :placeholder="index !== 0 ? 'Input another Universal Treatment' : 'Input a Universal Treatment'"
                v-model="field.text"
                :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                @click:append="deleteInput(index, 'UniversalTreatments')"
                >
                </v-text-field>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 


    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    single-expand
    :search="search"
    item-key=".key"
    show-expand
    class="elevation-1"
  >

    <template v-slot:expanded-item="{ item }">
      <td colspan="12">
        <v-row class="pl-5">
          <v-col cols="4"> 
            <v-list-item-title><h4>Universal Causes</h4></v-list-item-title>
            <v-list 
            v-for="(cause, index) in item.UniversalCauses"
            :key="index"
            ><v-list-item>{{ cause }}</v-list-item>
            </v-list>
          </v-col>
          
          <v-col cols="4">
            <v-list-item-title><h4>Universal Impact</h4></v-list-item-title>
            <v-list 
            v-for="(impact, index) in item.UniversalImpacts"
            :key="index"
            ><v-list-item>{{ impact }}</v-list-item>
            </v-list>
          </v-col>
          
          <v-col cols="4">
            <v-list-item-title><h4>Universal Treatments</h4></v-list-item-title>
            <v-list 
            v-for="(treatment, index) in item.UniversalTreatments"
            :key="index"
            ><v-list-item>{{ treatment }}</v-list-item>
            </v-list>
          </v-col>
          
        </v-row>
      </td>
    </template>
  
     <!-- <template v-slot:expanded-item="{ headers, item }">

  </template> -->

  <!-- <template v-slot:item.UniversalTreatments="{item}">
              
  </template> -->
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,

      search: '',
      headers: [
        {
          text: 'Universal Risk Name',
          align: 'start',
          sortable: false,
          value: 'RiskName',
        },
        { text: 'Risk Classification', value: 'RiskClassification.RiskClassification' },
        { text: 'Risk Universe', value: 'RiskUniverse' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      desserts: [],
      universalCausesInputs: '',
      editedIndex: -1,
      editedItem: '',
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

     firestore(){
      return {
        Risks: this.$db.collection('Risk'),
        RiskClassification: this.$db.collection('RiskClassification'),
        RiskUniverse: this.$db.collection('RiskUniverse'),
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.desserts = this.Risks
      },

       mapInput(arr){
         let i
         let mapThis = []
         for(i=0; i < arr.length; i++) {
           mapThis.push({text: arr[i]})
         }
         return mapThis 
        },

      addInput(arr){
          this.editedItem[arr].push({text: ''})
      },

      deleteInput(index, arr){
             this.editedItem[arr].splice(index, 1)
           
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = {
          itemKey: item['.key'],
          RiskName: item.RiskName,
          RiskClassification: item.RiskClassification,
          RiskUniverse: item.RiskUniverse,
          UniversalCauses: this.mapInput(item.UniversalCauses),
          UniversalImpacts: this.mapInput(item.UniversalImpacts),
          UniversalTreatments: this.mapInput(item.UniversalTreatments)
        }
        this.dialog = true
        this.mapInput(item.UniversalCauses)
      },

     async deleteItem (item) {
        let self = this

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this Risk?`,
                title: `Delete Risk`
            })

            if(confirm){
                 self.$db.collection('Risk').doc(item['.key']).delete()
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'Risk',documentKey:item['.key'],module: 'Risk Universe',action: 'Deleted Risk'}) 
                    self.$dialog.notify.success(`Delete Item from Risks`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
      },

      close () {
        this.dialog = false
          this.editedItem = '',
          this.editedIndex = -1
      },

      mapInputSave(arr){
          let values = this.editedItem[arr].map(function(value) {
                return value['text']
            });
            return values;
        },
        findId(id, arr) {
            let find = this[arr].find(e => {
              return e['.key'] === this.editedItem[id]
            })
            return find
        },

      async save() {
        let self = this
        let itemKey = this.editedItem.itemKey
        if (this.editedIndex > -1) {
            let finalData = {
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            RiskName: this.editedItem.RiskName,
            RiskClassification: this.editedItem.RiskClassification,
            RiskUniverse: this.editedItem.RiskUniverse,
            UniversalCauses: this.mapInputSave('UniversalCauses'),
            UniversalImpacts: this.mapInputSave('UniversalImpacts'),
            UniversalTreatments: this.mapInputSave('UniversalTreatments')
            }
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save the changes to this Risk?`,
                title: `Confirm Edit Risk`
            })

            if(confirm){
                  self.$db.collection('Risk').doc(itemKey).set(finalData)
                 .then(async ()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'Risk',documentKey:itemKey,module: 'Risk Universe',action: 'Edited Risk'}) 
                    self.$dialog.notify.success(`Successfully Edited Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                 
                 })
            }
          
        } else {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        }
        this.close()
      },
    },
  }
</script>

style <style scoped>
col-height{
   min-height: 10px;
}
</style>